<template>
    <Toast :baseZIndex="1" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="DistrictList" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="map1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">District</h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Import" icon="pi pi-paperclip" class="p-mr-2" @click="onImportDistrict" />
                            <Button label="Add District" icon="pi pi-plus" class="p-mr-2" @click="onAddDistrict" />
                            <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter" @click="onFilter()"></Button>
                            <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash" class="p-button-success"
                                @click="onFilter()"></Button>
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column header="Country" field="Country" headerStyle="width: 20%">
                        <template #body="{ data: { mbb3 } }">
                            <div class="p-text-capitalize">{{ mbb3 || 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="State" field="State" headerStyle="width: 30%">
                        <template #body="{ data: { mbb5 } }">
                            <div class="p-text-capitalize">{{ mbb5 || 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="District" field="District" headerStyle="width: 35%">
                        <template #body="{ data: { mbb6 } }">
                            <div class="p-text-capitalize">{{ mbb6 || 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 10%" class="p-text-center">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded " @click="onEditDistrict(data)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <!-- add District dialog start here -->
    <!-- <Dialog v-model:visible="isAddDistrict" :style="{ width: '800px' }" header="Add District" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="city">
                        City
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="city" v-model="city" :options="cityList" optionLabel="label"
                        placeholder="Select City" :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.city.$error">{{
                            v$.city.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="pin-code">
                        Pin-code
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="pin-code" v-model.trim="pincode" required="true"
                        maxlength="6" autofocus :class="{ 'p-invalid': isSubmitted && !pincode }" />
                    <small class="p-invalid p-error" v-if="v$.pincode.$error">{{
                            v$.pincode.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="District-name">
                        District
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="District-name" class="p-text-capitalize" v-model.trim="District" required="true"
                        maxlength="20" autofocus :class="{ 'p-invalid': isSubmitted && !District }" />
                    <small class="p-invalid p-error" v-if="v$.District.$error">{{
                            v$.District.$errors[0].$message
                    }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button label="Submit" :icon="`pi ${!showLoader ? 'pi-check' : 'pi-spin pi-spinner indigo-color'}`" class="p-button-text p-ml-auto"
                    @click="addDistrictBtn()" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button> 
            </div>
        </template>
    </Dialog> -->
    <!-- add District dialog end here -->

    <!--add edit Gram Panchayat dialog start here -->
    <Dialog v-model:visible="isAddEditDistrict" :style="{ width: '800px' }" header="Edit District" :modal="true"
        class="p-fluid">

        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
                <label for="country">
                    Country
                    <span class="p-invalid p-error">*</span>
                </label>
                <InputText id="country" class="p-text-capitalize" disabled v-model.trim="Country_name" required="true" />
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="state">
                    State
                    <span class="p-invalid p-error">*</span>
                </label>
                <Dropdown id="state" v-model="state" :options="stateList" optionLabel="label" placeholder="Select State"
                    :filter="true" appendTo="body"></Dropdown>
                <small class="p-invalid p-error" v-if="v$.state.$error">{{
                    v$.state.$errors[0].$message
                }}</small>
            </div>

            <div class="p-field p-col-12 p-md-6">
                <label for="district">
                    District
                    <span class="p-invalid p-error">*</span>
                </label>
                <InputText id="district" class="p-text-capitalize" v-model.trim="district" required="true" maxlength="20"
                    autofocus :class="{ 'p-invalid': isSubmitted && !district }" />
                <small class="p-invalid p-error" v-if="v$.district.$error">{{
                    v$.district.$errors[0].$message
                }}</small>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="addEditDistrictBtn()" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!--add edit Gram Panchayat dialog end here -->
    <!----Start of filter--------------------------------->
    <Dialog v-model:visible="isFilterDialog" :style="{ width: '600px' }" header="Filter" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <label for="state-filter">State</label>
                    <MultiSelect v-model="stateFilter" id="state-filter" :options="stateList" optionValue="value"
                        optionLabel="label" placeholder="Select state" :filter="true" class="multiselect-custom"
                        appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label for="district">District</label>
                    <InputText v-model.trim="DistrictFilter" placeholder="Enter District" maxlength="20"></InputText>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData(stateFilter, DistrictFilter)" />
        </template>
    </Dialog>
    <!----End of filter--------------------------------->

    <!-- import csv dialog start here -->
    <Dialog v-model:visible="isImportDistrict" :style="{ width: '1100px' }" header="Import District" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6" align="left">
                    <FileUpload mode="basic" ref="importDistrictfile" class="custom-upload-btn-ultima"
                        v-model="importDistrictfile" v-on:change="handleUploadImport()" />
                    <div style="color:red" v-if="importmsg.file">
                        {{ importmsg.file }}
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-6" align="right">
                    <label class="p-mr-2">
                        Download Sample Sheet:
                    </label>
                    <!-- TODO: Need CSV File URL here -->
                    <router-link href="https://storage.googleapis.com/meraneta_admin/import_district_data.csv" target="_blank" to="#">
                        <Button icon="pi pi-download" v-tooltip.left="'Download Sample Sheet'" class="p-button-rounded">
                        </Button>
                    </router-link>
                </div>
                <div class="p-field p-col-12">
                    <h5 class="indigo-color p-m-0 p-pl-1">Note:</h5>
                    <ol style="line-height: 1.5;">
                        <li>The file name should be same as sample file name "import_district_data.csv".</li>
                        <li>Do not just change any files extension from .XLS or any other extension to CSV and upload it.
                        </li>
                        <li>You can upload enquiries in one sheet up to 5000 enquiries in one attempt.</li>
                        <li>Don't change and delete any column name in CSV sheet.</li>
                        <li>Date format must be in "dd-mm-yyyy".</li>
                        <li>Insert employee mobile number properly to allocate the enquiry to a particular employee, else
                            enquiry will be inserted into logged-in employees Lok-neta account.</li>
                        <li>Project name, unit type & source must be the same as in the Lok-neta System.</li>
                        <li>In CSV sheet enquiry date, first name or last name, mobile or email, employee mobile, source
                            column's are mandatory.</li>
                        <li>Submitted sheets for importing district data till 10 pm will be executed on the same night and the
                            result will be available by 7 am next day</li>
                    </ol>
                </div>
            </div>
        </div>
        <template #footer>
            <Button v-if="!importfileloader" label="Submit" icon="pi pi-check" class="p-button-text" :disabled="importmsg.file != '' ||
                importDistrictfile == null ||
                importDistrictfile == ''
                " @click="onCSVSubmit" style="width: 90px" />
            <Button v-show="importfileloader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>
    <!-- import dialog end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
export default {
    data() {
        return {
            v$: useValidate(),
            page_no: 0,
            DistrictList: [],
            totalRecords: 0,
            loading: false,
            // isAddDistrict: false,
            state: '',
            stateList: [],
            district: '',
            Country_id: 101,
            Country_name: 'India',
            isImportDistrict: false,
            showLoader: false,
            isSubmitted: false,
            row_id: null,
            isFilterDialog: false,
            showFillFilter: false,
            isAddEditDistrict: false,
            importfileloader: false,
            importmsg: [],
            importDistrictfile: "",
            DistrictFilter:'',
            stateFilter:'',
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getDistrictList();
        this.getStates();
    },
    validations() {
        return {
            state: { required: helpers.withMessage('Please select state', required) },
            district: { required: helpers.withMessage('Please enter district', required) },
        };
    },
    methods: {

        getStates(ev) {
            this.ApiService.getStates(ev).then((data) => {
                if (data.status == 200) {
                    this.stateList = data.data;
                } else {
                    this.stateList = '';
                }
            });
        },
        handleUploadImport() {
            var file = this.$refs.importDistrictfile.files[0];
            let allowedExtensions = /(\.csv)$/i;
            if (!file) {
                this.importmsg["file"] = "Please upload CSV excel file.";
            } else if (!file.size > 2048) {
                this.importmsg["file"] = "File size cannot exceed more than 2MB";
            } else if (!file.name.match(allowedExtensions)) {
                this.importmsg["file"] = "Invalid file type";
            } else {
                this.importmsg["file"] = "";
                this.importDistrictfile = this.$refs.importDistrictfile.files[0];
            }
        },
        onImportDistrict() {
            this.isImportDistrict = true;
        },
        onAddDistrict() {
            try {
                this.isAddEditDistrict = true;
                this.state = '';
                this.Country_id = 101;
                this.Country_name = "India";
                this.district = '';
                this.row_id = 0;
                this.showLoader = false;
                setTimeout(() => {
                    this.v$.$reset();
                }, 0);
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            }
        },
        // addDistrictBtn() {
        //     this.v$.$validate();
        //     let fields = {};
        //     fields['row_id'] = 0;
        //     if (this.state.value) {
        //         fields["mbb4"] = this.state.value;
        //         fields["mbb5"] = this.state.label;
        //     }
        //     fields["mbb6"] = this.district;
        //     if (!this.v$.$error) {
        //         this.showLoader = true;
        //         this.ApiService.addUpdateDistrict(fields).then((items) => {
        //             if (items.status == 200) {
        //                 var successMsg = items.message;
        //                 this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
        //                 setTimeout(() => {
        //                     this.v$.$reset();
        //                 }, 0);
        //                 this.isAddEditDistrict = false;
        //                 this.showLoader = false;
        //                 this.state = '';
        //                 this.district = '';
        //                 this.getDistrictList();
        //             } else {
        //                 var errorMsg = items.message;
        //                 this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
        //                 this.showLoader = false;
        //             }
        //         });
        //     }
        // },
        onEditDistrict(e) {
            try {
                this.isAddEditDistrict = true;
                this.state = '';
                this.district = '';
                this.showLoader = false;
                this.row_id = e.mbb1;
                this.Country_id = e.mbb2;
                this.Country_name = e.mbb3;
                this.district = e.mbb6;
                let mbb4 = this.stateList.filter(function (item) {
                    return item.value == e.mbb4;
                });
                if (mbb4.length > 0) {
                    this.state = mbb4[0];
                }
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            }
        },
        addEditDistrictBtn() {
            this.v$.$validate();
            let fields = {};
            fields["mbb1"] = this.row_id;
            if (this.state.value) {
                fields["mbb4"] = this.state.value;
                fields["mbb5"] = this.state.label;
            }
            fields["mbb6"] = this.district;
            // console.log(fields);
            if (!this.v$.$error) {
                this.showLoader = true;
                this.ApiService.addUpdateDistrict(fields).then((items) => {
                    if (items.status == 200) {
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.isAddEditDistrict = false;
                        this.showLoader = false;
                        this.state = '';
                        this.district = '';
                        this.getDistrictList();

                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                        this.showLoader = false;
                    }
                });
            }
        },
        onCSVSubmit() {
            this.importfileloader = true;
            var formData = new FormData();
            formData.append("importfile", this.importDistrictfile);
            this.ApiService.importDistrictData(formData).then((items) => {
                if (items.success == true) {
                    var successMsg = items.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    this.isImportDistrict = false;
                    this.getDistrictList();
                    this.importfileloader = false;
                    this.importDistrictfile = "";
                } else {
                    var errorMsg = items.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                }
            });
        },
        getDistrictList(ev) {
            this.loading = true;
            this.ApiService.getDistrictList(ev).then((data) => {
                if (data.status == 200) {
                    this.DistrictList = data.result;
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.DistrictList = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getDistrictList({ page_no: event.page, statefilter: this.event_state, loksabhafilter: this.event_loksabha });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },

        onFilter() {
            this.isFilterDialog = true;
        },
        getFilteredData() {
            this.loading = true;
            this.getDistrictList({ 
                stateFilter: this.stateFilter,
                Districtfilter: this.DistrictFilter
             });
            this.showFillFilter = !!(this.stateFilter || this.DistrictFilter);
            this.isFilterDialog = false;
        },
        reSetFilteredData() {
            this.stateFilter = "";
            this.DistrictFilter = '';
            this.getDistrictList();
            this.showFillFilter = false;
            this.isFilterDialog = false;
        }
    },
};
</script>
<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
